import React from 'react'
import { Link } from 'react-router-dom'
import Logo from '../images/logo-cropped2.png'

function Navbar() {
  return (
      <div className='navbar'>
          <img src={Logo} alt='Illustrious Title' className='logo' />
          <div className='navbarRight'>
              <Link to="/">Home</Link>
              {/* <Link to="/about">About</Link> */}
              <Link to="/contact">Contact</Link>
          </div>
      </div>
  )
}

export default Navbar
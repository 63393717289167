import React from 'react'
import { Link } from 'react-router-dom';

function Home() {
  return (
    <div className='home'>
      <div className='top'>
        <h1 className='homeLeft'>What Do We Do?</h1>
        <div className='homeRight'>
          Illustrious Title, LLC, offers comprehensive real estate services,
          including representing buyers, sellers, lenders, and borrowers in
          purchase and refinancing transactions. We provide title insurance and
          settlement services, conduct thorough title and lien searches,
          coordinate document execution, manage closing and settlement processes,
          utilize electronic recording for fast document registration, and
          facilitate 1031 exchanges, all at a reasonable cost.
        </div>
      </div>
      <div className="bottom">
        <h1 className='homeHelp'>Need more info? <Link className='clickHere' to="/contact"><b>Contact Us Here</b></Link></h1>
      </div>
    </div>
  );
}

export default Home
import React from 'react'

function Footer() {
  return (
    <div className="footer">
      <div>Fax: 954-416-6277</div>
      <div>Office: 954-932-6616</div>
      <div>Email: Orders@illustrioustitle.com</div>
      <div>© 2024 by Illustrious Title, LLC. All rights reserved.</div>
    </div>
  );
}

export default Footer
// import logo from './logo.svg';
import './App.css';
// import { Route, Routes } from "react-router-dom";
// import Home from './pages/Home';
// import About from './pages/About';
// import Contact from './pages/Contact';
import Layout from './Layout';
import Footer from './componants/Footer';
import Navbar from './componants/Navbar';

function App() {
  return (
    <div className="App">
      <Navbar />
      <Layout />
      <Footer />
      </div>
  );
}

export default App;

import React, { useRef, useState } from 'react';
import emailjs from "@emailjs/browser";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function Contact() {

  const [active, setActive] = useState(true)

    const form = useRef();

    const sendEmail = (e) => {
      e.preventDefault();

      emailjs
        .sendForm("service_hyurfk7", "template_w48f0ic", form.current, {
          publicKey: "K6ylL_5YnDWZzzClA",
        })
        .then(
          () => {
            console.log("SUCCESS!");
          },
          (error) => {
            console.log("FAILED...", error.text);
          }
        );
        e.target.reset();
       
    };

    const handleChange = (e) => {
      if(e.target.value.length >= 8) {
        setActive(false)
      }
    };

    const notify = () =>
      toast.success('Form Submitted', {
        position: "bottom-center",
        autoClose: 4500,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
        });;

  

  return (
    <div className="contactPage">
      <h1>Contact Illustrious Title</h1>
      <h3>Ask us how we can help you</h3>
      <p>
        For questions or comments, please feel free to reach out to us using the
        contact form below.
      </p>
      <form ref={form} onSubmit={sendEmail} className="form">
        <input type="text" name="from_name" placeholder="Full Name" required minLength={3} />
        <input
          type="email"
          name="email_address"
          placeholder="Email Address"
          required
          minLength={7}
        />
        <input
          type="phone"
          name="phone_number"
          placeholder="Phone Number"
          required
          minLength={10}
        />
        <textarea
          name="message"
          placeholder="How Can We Help You?"
          id="text"
          required
          minLength={8}
          onChange={handleChange}
        />
        <input
          onClick={notify}
          type="submit"
          value="SUBMIT"
          id="submit"
          disabled={active}
        />
        <ToastContainer />
      </form>
    </div>
  );
}

export default Contact